export var srcSetSettingImgVariationA = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      h: 92,
      w: 232
    },
    desktop: {
      h: 78,
      w: 198
    },
    tabletL: {
      h: 52,
      w: 144
    },
    tabletP: {
      h: 52,
      w: 144
    },
    mobile: {
      h: 52,
      w: 144
    }
  }
};
export var srcSetSettingImgVariationAEquine = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      h: 106,
      w: 267
    },
    desktop: {
      h: 90,
      w: 228
    },
    tabletL: {
      h: 63,
      w: 173
    },
    tabletP: {
      h: 63,
      w: 173
    },
    mobile: {
      h: 63,
      w: 173
    }
  }
};
export var srcSetSettingImgVariationB = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      h: 132,
      w: 306
    },
    desktop: {
      h: 62,
      w: 142
    },
    tabletL: {
      h: 68,
      w: 160
    },
    tabletP: {
      h: 68,
      w: 160
    },
    mobile: {
      h: 68,
      w: 160
    }
  }
};
export var srcSetSettingImgMiniFooter = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      h: 92,
      w: 198
    },
    desktop: {
      h: 78,
      w: 198
    },
    tabletL: {
      h: 117,
      w: 144
    },
    tabletP: {
      h: 117,
      w: 144
    },
    mobile: {
      h: 117,
      w: 144
    }
  }
};
export var srcSetSettingImgMultiLocation = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      h: 92,
      w: 198
    },
    desktop: {
      h: 78,
      w: 198
    },
    tabletL: {
      h: 117,
      w: 144
    },
    tabletP: {
      h: 117,
      w: 144
    },
    mobile: {
      h: 117,
      w: 144
    }
  }
};
export var srcSetSettingImgMapVariationA = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      w: 218,
      h: 193
    },
    desktop: {
      w: 167,
      h: 162
    },
    tabletL: {
      w: 198,
      h: 193
    },
    tabletP: {
      w: 280,
      h: 273
    },
    mobile: {
      w: 280,
      h: 273
    }
  }
};
export var srcSetSettingImgMapVariationB = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      w: 218
    },
    desktop: {
      w: 218
    },
    tabletL: {
      w: 226
    },
    tabletP: {
      w: 262
    },
    mobile: {
      w: 280
    }
  }
};
export var getSrcSetSettingImg = function getSrcSetSettingImg(variation) {
  var isEquine = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  switch (variation) {
    case 'Variation-A':
      return isEquine ? srcSetSettingImgVariationAEquine : srcSetSettingImgVariationA;
    case 'Mini-Footer':
      return srcSetSettingImgMiniFooter;
    case 'Variation-B':
      return srcSetSettingImgVariationB;
    case 'Multi-location-footer':
      return srcSetSettingImgMultiLocation;
    default:
      return;
  }
};