import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "type", "name", "autocomplete", "onChange", "label", "labelProps", "fieldProps", "ariaLabel"];
var __jsx = React.createElement;
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import Input from '../../../atoms/Input';
import FieldError from '../../../atoms/FieldError';
import Label from '../../../atoms/Label';
import styles from './FieldInput.style';
import Exclamation from '../../../../static/images/icons/Exclamation';
var FieldInput = function FieldInput(_ref) {
  var id = _ref.id,
    className = _ref.className,
    type = _ref.type,
    name = _ref.name,
    autocomplete = _ref.autocomplete,
    _onChange = _ref.onChange,
    label = _ref.label,
    labelProps = _ref.labelProps,
    fieldProps = _ref.fieldProps,
    ariaLabel = _ref.ariaLabel,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: className
  }, __jsx(Field, _extends({
    id: id,
    name: name,
    type: type
  }, fieldProps), function (_ref2) {
    var field = _ref2.field,
      form = _ref2.form;
    var touched = form.touched,
      errors = form.errors,
      handleChange = form.handleChange;
    return __jsx("div", {
      className: touched[field.name] && errors[field.name] ? 'error input-wrapper field' : 'input-wrapper field'
    }, __jsx(Input, _extends({
      type: type,
      className: "form-control",
      autoComplete: autocomplete ? 'nah' : ''
    }, others, field, {
      onChange: function onChange(e) {
        handleChange(e);
        if (typeof _onChange === 'function') _onChange(e, form);
      },
      id: id,
      ariaLabel: ariaLabel,
      placeholder: " "
    })), __jsx(Label, _extends({
      className: "form-control-placeholder",
      htmlFor: id
    }, labelProps), label), touched[field.name] && errors[field.name] ? __jsx(Exclamation, null) : '');
  }), __jsx(ErrorMessage, {
    name: name,
    component: FieldError
  }));
};
FieldInput.defaultProps = {
  placeholder: '',
  className: '',
  type: 'text',
  labelProps: {},
  value: '',
  fieldProps: {},
  onChange: function onChange() {}
};
export default styled(FieldInput).withConfig({
  componentId: "sc-nt2ww9-0"
})(["", ";"], styles);
export { FieldInput as FieldInputVanilla };