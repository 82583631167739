// const urlSlug = require('url-slug');

var urlConstants = require('../../src/constants/routes').urlConstants;

// TODO: Replace this whole module with a real router

/**
 * Builds a root-relative URL path from a list of segements
 * @param {[String]} segments List of segments for a URL
 * @param {Boolean} relative Set to true to get a locally-relative path (no prefixed '/')
 */
export var getPath = function getPath(segments, relative) {
  segments = segments.map(function (segment) {
    return segment.toString().toLowerCase().replace(/[&#,+()$~%.'":*?<>{}]/g, '-').replace(' ', '-');
  });

  // Prefix with a '/'
  segments.unshift('');
  if (relative === true) {
    segments.shift();
  }
  return segments.join('/');
};
export var getUrl = function getUrl(_ref) {
  var domain = _ref.domain,
    segments = _ref.segments;
  var hasSubdomain = /\b(?!www\.)(\w+(?:-\w+)*)(?=\.\w+\.com\b)/i;
  var result = domain.match(hasSubdomain);
  if (result != null) {
    domain = domain.replace('www.', '');
    if (domain.substr(0, 8) !== 'https://') {
      domain = "https://".concat(domain);
    }
  } else {
    domain = domain.replace(/^https?:\/\//, '').replace('www.', '');
    domain = "https://www.".concat(domain);
  }
  return "".concat(domain).concat(segments);
};