import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Exclamation(props) {
  return __jsx("svg", _extends({
    "data-name": "noun_exclamation point_1896534_000000",
    viewBox: "0 0 20 20"
  }, props), __jsx("path", {
    d: "M10 0A10 10 0 000 10a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0010 0zm0 2.358a1.828 1.828 0 011.832 1.832v7.326A1.828 1.828 0 0110 13.347a1.828 1.828 0 01-1.832-1.832V4.189A1.828 1.828 0 0110 2.358zm0 11.874a1.705 1.705 0 011.705 1.705A1.705 1.705 0 0110 17.642a1.705 1.705 0 01-1.706-1.705A1.705 1.705 0 0110 14.232z",
    "data-name": "Path 701",
    fill: "red",
    fillRule: "evenodd"
  }));
}
export default Exclamation;