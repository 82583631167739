import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "type", "name", "label", "labelProps", "fieldProps", "ariaLabel", "startDate"];
var __jsx = React.createElement;
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import FieldError from '../../../atoms/FieldError';
import Label from '../../../atoms/Label';
import styles from './FieldDate.style';
import Exclamation from '../../../../static/images/icons/Exclamation';
// import Datepicker from '../../Datepicker';

var FieldDate = function FieldDate(_ref) {
  var id = _ref.id,
    className = _ref.className,
    type = _ref.type,
    name = _ref.name,
    label = _ref.label,
    labelProps = _ref.labelProps,
    fieldProps = _ref.fieldProps,
    ariaLabel = _ref.ariaLabel,
    startDate = _ref.startDate,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: className
  }, __jsx(Field, _extends({
    id: id,
    name: name,
    type: type,
    render: function render(_ref2) {
      var field = _ref2.field,
        _ref2$form = _ref2.form,
        touched = _ref2$form.touched,
        errors = _ref2$form.errors,
        setFieldValue = _ref2$form.setFieldValue;
      return typeof window !== 'undefined' ? __jsx("div", {
        className: touched[field.name] && errors[field.name] ? 'error input-wrapper' : 'date-wrapper'
      }, __jsx(Label, _extends({
        htmlFor: id
      }, labelProps), label), touched[field.name] && errors[field.name] ? __jsx(Exclamation, null) : '') : '';
    }
  }, fieldProps)), __jsx(ErrorMessage, {
    name: name,
    component: FieldError
  }));
};
FieldDate.defaultProps = {
  placeholder: '',
  className: '',
  type: 'text',
  labelProps: {},
  value: '',
  fieldProps: {}
};
export default styled(FieldDate).withConfig({
  componentId: "sc-1eq8v8i-0"
})(["", ";"], styles);
export { FieldDate as FieldDateVanilla };