import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "name", "label", "onChange", "type", "labelProps", "fieldProps", "value"];
var __jsx = React.createElement;
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import Input from '../../../atoms/Input';
import FieldError from '../../../atoms/FieldError';
import Label from '../../../atoms/Label';
import styles from './FieldCheckbox.style';
var FieldCheckbox = function FieldCheckbox(_ref) {
  var id = _ref.id,
    className = _ref.className,
    name = _ref.name,
    label = _ref.label,
    _onChange = _ref.onChange,
    type = _ref.type,
    labelProps = _ref.labelProps,
    fieldProps = _ref.fieldProps,
    value = _ref.value,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: className
  }, __jsx(Field, _extends({
    id: id,
    name: name,
    type: type,
    render: function render(_ref2) {
      var field = _ref2.field,
        form = _ref2.form;
      var touched = form.touched,
        errors = form.errors,
        handleChange = form.handleChange,
        values = form.values;
      return __jsx("div", {
        className: touched[field.name] && errors[field.name] ? 'error checkbox-wrapper' : 'checkbox-wrapper'
      }, __jsx(Input, _extends({
        type: type
      }, others, field, {
        value: value,
        id: id,
        checked: values[field.name].includes(value),
        "aria-checked": values[field.name].includes(value),
        onChange: function onChange(e) {
          handleChange(e);
          if (typeof _onChange === 'function') _onChange(e, form);
        }
      })), __jsx(Label, _extends({
        htmlFor: id
      }, labelProps), label));
    }
  }, fieldProps)), __jsx(ErrorMessage, {
    name: name,
    component: FieldError
  }));
};
FieldCheckbox.defaultProps = {
  placeholder: '',
  className: '',
  type: 'checkbox',
  labelProps: {},
  fieldProps: {},
  onChange: function onChange() {}
};
export default styled(FieldCheckbox).withConfig({
  componentId: "sc-1gxb6y8-0"
})(["", ";"], styles);
export { FieldCheckbox as FieldCheckboxVanilla };