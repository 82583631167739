import { css } from 'styled-components';
var styles = css(["textarea{padding:10px;min-width:100%;max-width:100%;color:var(--sc-font-color-p,", ");font-weight:normal;font-family:var(--sc-font-family-p,", ");font-size:var(--sc-font-size-p,", ");line-height:var(--sc-line-height-p,", ");letter-spacing:var(--sc-letter-spacing-p,", ");}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fontColorP;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.fontFamilyP;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.fontSizeP;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.lineHeightP;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.letterSpacingP;
});
export default styles;