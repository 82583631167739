// TODO: replace this and /internal/utils/router.js with a real router

var blog = ['blog'];
var blogCategories = blog.concat(['category']);
var newsroom = ['newsroom'];
var newsroomCategories = newsroom.concat(['category']);
var testimonials = ['testimonials'];
module.exports = {
  routes: {
    blog: blog,
    blogCategories: blogCategories,
    newsroom: newsroom,
    newsroomCategories: newsroomCategories,
    testimonials: testimonials
  },
  urlConstants: {
    protocol: 'https',
    subdomain: 'www'
  }
};