var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './MultiLocationGrid.style';

// import { YextMockData } from './MultiLocationGrid.mock';
import GridItem from './GridItem';
import { useYextFetch } from '../../../../hooks/useYextFetch';
import analyticsConstants from '../../../../constants/analyticsConstants';
var MultiLocationGridBlock = styled.section.withConfig({
  componentId: "sc-2gqs29-0"
})(["", ";"], styles);
var MultiLocationGrid = function MultiLocationGrid(_ref) {
  var _finalSingles;
  var className = _ref.className,
    multiLocationMenuContainers = _ref.multiLocationMenuContainers,
    globalFooterProps = _ref.globalFooterProps;
  // get yext id's from the "single input" field in contentful
  var yextSingleIds = multiLocationMenuContainers && multiLocationMenuContainers.yextIds || '';

  // get yext folder id's from the "folder input" field in contentful
  var yextFolderIds = multiLocationMenuContainers && multiLocationMenuContainers.yextFolderIds || '';
  var finalSingles;
  var finalFolders;
  var yextLocationsInformationData =
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yextSingleIds && useYextFetch(yextSingleIds, false);
  var yextFolderInformationData =
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yextFolderIds && useYextFetch(yextFolderIds, true);
  finalSingles = yextSingleIds.length > 1 && yextLocationsInformationData && yextLocationsInformationData.entities || yextSingleIds.length == 1 && yextLocationsInformationData && yextLocationsInformationData;
  finalFolders = yextFolderInformationData && yextFolderInformationData.entities;
  var sortedList = finalSingles && Array.isArray(finalSingles) && ((_finalSingles = finalSingles) === null || _finalSingles === void 0 ? void 0 : _finalSingles.sort(function (a, b) {
    return yextSingleIds.indexOf(a.meta.id) - yextSingleIds.indexOf(b.meta.id);
  }));
  return __jsx(MultiLocationGridBlock, {
    className: className,
    globalFooterProps: globalFooterProps
  }, __jsx("div", {
    className: "multi-location-grid"
  }, sortedList && yextSingleIds.length > 1 && sortedList.map(function (item) {
    return __jsx(GridItem, {
      key: item.meta.id,
      item: item,
      dataAnalyticsValue: analyticsConstants.multiLocationGrid.address.analyticsValue,
      dataAnalyticsTypeCard: analyticsConstants.multiLocationGrid.address.analyticsTypeCard,
      dataAnalyticsTypeMap: analyticsConstants.multiLocationGrid.address.analyticsTypeMap
    });
  }), finalSingles && finalSingles.meta && __jsx(GridItem, {
    key: 'single',
    item: finalSingles,
    dataAnalyticsValue: analyticsConstants.multiLocationGrid.address.analyticsValue,
    dataAnalyticsTypeCard: analyticsConstants.multiLocationGrid.address.analyticsTypeCard,
    dataAnalyticsTypeMap: analyticsConstants.multiLocationGrid.address.analyticsTypeMap
  }), finalFolders && finalFolders.map(function (item) {
    return __jsx(GridItem, {
      key: item.meta.id,
      item: item,
      dataAnalyticsValue: analyticsConstants.multiLocationGrid.address.analyticsValue,
      dataAnalyticsTypeCard: analyticsConstants.multiLocationGrid.address.analyticsTypeCard,
      dataAnalyticsTypeMap: analyticsConstants.multiLocationGrid.address.analyticsTypeMap
    });
  })));
};
MultiLocationGrid.defaultProps = {
  className: ''
};
export default MultiLocationGrid;