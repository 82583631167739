import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "name", "label", "labelProps", "onChange", "fieldProps", "ariaLabel"];
var __jsx = React.createElement;
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import FieldError from '../../../atoms/FieldError';
import Label from '../../../atoms/Label';
import styles from './FieldTextArea.style';
var FieldTextArea = function FieldTextArea(_ref) {
  var id = _ref.id,
    className = _ref.className,
    name = _ref.name,
    label = _ref.label,
    labelProps = _ref.labelProps,
    _onChange = _ref.onChange,
    fieldProps = _ref.fieldProps,
    ariaLabel = _ref.ariaLabel,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: className
  }, __jsx(Label, _extends({
    htmlFor: id
  }, labelProps), label), __jsx(Field, _extends({
    id: id,
    name: name,
    render: function render(_ref2) {
      var field = _ref2.field,
        form = _ref2.form;
      var handleChange = form.handleChange;
      return __jsx("textarea", _extends({}, others, field, {
        "aria-label": ariaLabel,
        id: id,
        onChange: function onChange(e) {
          handleChange(e);
          if (typeof _onChange === 'function') _onChange(e, form);
        }
      }));
    }
  }, fieldProps)), __jsx(ErrorMessage, {
    name: name,
    component: FieldError
  }));
};
FieldTextArea.defaultProps = {
  placeholder: '',
  className: '',
  labelProps: {},
  fieldProps: {}
};
export default styled(FieldTextArea).withConfig({
  componentId: "sc-wzz1l4-0"
})(["", ";"], styles);
export { FieldTextArea as FieldTextAreaVanilla };