export var srcSetSettingImg = {
  quality: 75,
  imageRenditions: {
    desktopL: {
      w: 124
    },
    desktop: {
      w: 124
    },
    tabletL: {
      w: 124
    },
    tabletP: {
      w: 124
    },
    mobile: {
      w: 124
    }
  }
};